* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiTypography-h5 {
  font-size: 20px !important;
}

.translation_input {
  font-weight: 400;
  height: auto;
  min-height: 40px;
  position: relative;
  z-index: 1;
  width: 100%;
  border: none;
  background-color: transparent;
  padding: 0 10px;
}

.card-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pos-product-card {
  width: 200px;
  margin: 4px;
  // border-radius: 2px !important;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 5px 5px -1px rgba(63, 63, 68, 0.15) !important;
}
.pos-product-img {
  height: 150px;
}

.product-content {
  width: calc(100% - 300px);
}

.pos-product-price {
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 3px 5px;
  background: #ffc107;
  color: black;
  border-radius: 2px;
}

.product-order {
  width: 300px;
  background: white;
  height: 100%;
  position: fixed;

  right: 0;
  box-shadow: 6px 0px 10px 2px rgba(0, 0, 0, 0.6784313725490196);
  top: 0;
}
.product-order-head {
  background-color: #f5f5f5;
  padding: 14px 15px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
}

.product-order-head p {
  font-size: 18px;
  font-weight: bold;
}
.product-order-head p:nth-child(2) {
  margin-left: auto;
}
.product-order .MuiTypography-body1 {
  font-size: 12px;
  font-weight: bold;
}
.product-order-actions {
  position: absolute;
  width: 100%;
  
  background: white;
  bottom: 0;
  padding: 15px;
}

.product-order-actions button {
  width: 100%;
  height: 50px;
}

.lactive li {
  background: rgba(33, 150, 243, 0.32);
}

.product-order-actions button .MuiButton-label {
  display: initial;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:16px;
}

.loading_c {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.loading_text {
  font-family: sans-serif;
  font-weight: bold;
  margin-top: 18px;
  font-size: 13px;
  color: #bed0de;
  text-transform: uppercase;
}

.repertuar_index_date {
  font-weight: bold;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-align: center;
}

@media print {
  header,
  .MuiDrawer-root,
  .printHide,
  .MuiTypography-root {
    display: none !important;
  }
  #root * {
    padding: 0;
  }
}
